<template>
    <footer id="bottom" class="footer-dark ftr-light-with-bg site-bg-cover"
        style="background-image: url(images/ftr-bg.jpg);">
        <div class="container">

            <!-- FOOTER BLOCKES START -->
            <div class="footer-top">
                <div class="row">

                    <div class="col-lg-3 col-md-12">

                        <div class="widget widget_about">
                            <div class="logo-footer clearfix">
                                <a href="#" class="d-flex align-items-baseline">
                                    <h2 class="text-light">Mosala</h2><small class="text-primary">.site</small>
                                </a>
                            </div>
                            <p>Explorez tous les postes professionnels les plus passionnants en fonction de votre
                                intérêt et de votre
                                spécialisation. l'emploi de vos rêves vous attend. </p>
                            <ul class="ftr-list">
                                <li>
                                    <p><span>Adresse :</span>03, Bismarck, Golf Gombe Kinshasa </p>
                                </li>
                                <li>
                                    <p><span>Email :</span>info@mosala.site</p>
                                </li>
                                <li>
                                    <p><span>Téléphone :</span>+(243) 971464803</p>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div class="col-lg-9 col-md-12">
                        <div class="row">

                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="widget widget_services ftr-list-center">
                                    <h3 class="widget-title">Domaines</h3>
                                    <ul>
                                        <li><a href="Javascript:void(0)">Comptabilité</a></li>
                                        <li><a href="Javascript:void(0)">Architecture</a></li>
                                        <li><a href="Javascript:void(0)">Banque & assurance</a></li>
                                        <li><a href="Javascript:void(0)">Médical</a></li>
                                        <li><a href="Javascript:void(0)">Marketing</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="widget widget_services ftr-list-center">
                                    <h3 class="widget-title">Domaines</h3>
                                    <ul>
                                        <li><a href="Javascript:void(0)">Informatique & Télécom</a></li>
                                        <li><a href="Javascript:void(0)">Electricité</a></li>
                                        <li><a href="Javascript:void(0)">Banque & assurance</a></li>
                                        <li><a href="Javascript:void(0)">Droit</a></li>
                                        <li><a href="Javascript:void(0)">Transport</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="widget widget_services ftr-list-center">
                                    <h3 class="widget-title">Liens rapides</h3>
                                    <ul>
                                        <li><a href="#">Accueil</a></li>
                                        <li><a href="#">Comment ça marche ?</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <!-- FOOTER COPYRIGHT -->
            <div class="footer-bottom">

                <div class="footer-bottom-info">
                    <div class="footer-copy-right">
                        <span class="copyrights-text"> © 2023 Millenium Horizon. Tout droit reservé.</span>
                    </div>
                    <ul class="social-icons">
                        <li>
                            <a href="javascript:void(0);" class="fab fa-facebook-f"></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="fab fa-twitter"></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="fab fa-instagram"></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="fab fa-youtube"></a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterLayout"
}
</script>