<template>
    <div class="modal fade" id="privacyModal" tabindex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="privacyModalLabel">Règles et Politique de Confidentialité</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-2">
                    <div class="mb-3">
                        <h2>Introduction</h2>
                        <p>Bienvenue sur <a href="http://mosala.site">mosala.site</a> . Nous nous engageons à protéger
                            votre vie privée et à respecter
                            vos droits en matière de protection des données personnelles. Cette politique de
                            confidentialité décrit comment nous recueillons, utilisons, et protégeons vos informations
                            personnelles.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Collecte des Informations</h2>
                        <p>Nous pouvons collecter les informations suivantes :</p>
                        <ul>
                            <li>Informations personnelles : nom, adresse e-mail, numéro de téléphone, adresse physique.
                            </li>
                            <li>Informations professionnelles : CV, lettres de motivation, diplômes, certifications,
                                expériences professionnelles.</li>
                            <li>Informations de navigation : adresse IP, type de navigateur, pages visitées, durée de la
                                visite.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Utilisation des Informations</h2>
                        <p>Les informations collectées peuvent être utilisées pour :</p>
                        <ul>
                            <li>Faciliter le processus de recrutement et de candidature.</li>
                            <li>Communiquer avec les utilisateurs concernant leurs candidatures et offres d'emploi.</li>
                            <li>Améliorer nos services et personnaliser l'expérience utilisateur.</li>
                            <li>Respecter nos obligations légales et réglementaires.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Partage des Informations</h2>
                        <p>Nous pouvons partager vos informations avec :</p>
                        <ul>
                            <li>Employeurs : pour faciliter le processus de recrutement.</li>
                            <li>Prestataires de services : pour fournir des services en notre nom (ex : hébergement de
                                site, analyses).</li>
                            <li>Autorités légales : lorsque requis par la loi.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Sécurité des Informations</h2>
                        <p>Nous prenons des mesures de sécurité appropriées pour protéger vos informations personnelles
                            contre l'accès non autorisé, l'altération, la divulgation ou la destruction.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Vos Droits</h2>
                        <p>Vous avez le droit de :</p>
                        <ul>
                            <li>Accéder à vos informations personnelles.</li>
                            <li>Demander la correction ou la suppression de vos informations personnelles.</li>
                            <li>Vous opposer au traitement de vos informations personnelles.</li>
                            <li>Retirer votre consentement à tout moment.</li>
                        </ul>
                        <p>Pour exercer ces droits, veuillez nous contacter à [Adresse e-mail de Contact].</p>
                    </div>

                    <div class="mb-3">
                        <h2>Cookies</h2>
                        <p>Notre site utilise des cookies pour améliorer l'expérience utilisateur. Vous pouvez contrôler
                            l'utilisation des cookies via les paramètres de votre navigateur.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Modifications de la Politique de Confidentialité</h2>
                        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
                            Les modifications seront publiées sur cette page avec une date de mise à jour.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Contact</h2>
                        <p>Pour toute question ou préoccupation concernant cette politique de confidentialité, veuillez
                            nous contacter à :</p>
                        <p>Millenium Horizon RD Congo<br>
                            03, Bismark, Golf, Gombe Kinshasa<br>
                            contact@milleniumhorizon.com<br>
                            +(243) 83 30 00 425</p>
                    </div>

                    <h1>Règles du Site</h1>

                    <div class="mb-3">
                        <h2>Acceptation des Conditions</h2>
                        <p>En utilisant notre site, vous acceptez les présentes conditions d'utilisation. Si vous
                            n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Utilisation du Site</h2>
                        <ul>
                            <li>Utilisation légale : Vous vous engagez à utiliser notre site de manière légale et
                                éthique.</li>
                            <li>Contenu : Vous ne publierez pas de contenu diffamatoire, offensant, ou illégal.</li>
                            <li>Respect de la Vie Privée : Vous respecterez la vie privée des autres utilisateurs.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Inscription et Compte</h2>
                        <ul>
                            <li>Informations Véridiques : Vous fournirez des informations exactes lors de l'inscription.
                            </li>
                            <li>Sécurité du Compte : Vous êtes responsable de la sécurité de votre compte et de la
                                confidentialité de votre mot de passe.</li>
                            <li>Utilisation Unique : Un compte est destiné à un seul utilisateur. Vous ne partagerez pas
                                votre compte avec d'autres personnes.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Contenu Utilisateur</h2>
                        <ul>
                            <li>Responsabilité : Vous êtes responsable du contenu que vous publiez sur le site.</li>
                            <li>Propriété : Vous garantissez que vous possédez les droits sur le contenu que vous
                                publiez ou que vous avez l'autorisation de le faire.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Limitation de Responsabilité</h2>
                        <p>Nous ne sommes pas responsables des dommages directs ou indirects résultant de l'utilisation
                            du site.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Résiliation</h2>
                        <p>Nous nous réservons le droit de suspendre ou de résilier votre compte à tout moment, sans
                            préavis, en cas de violation des présentes conditions d'utilisation.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Lois Applicables</h2>
                        <p>Ces conditions d'utilisation sont régies par les lois en vigueur en République Démocratique
                            du Congo. En cas de
                            litige, les tribunaux de la ville de Kinshasa seront compétents.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>